.section-recent-posts {
    background-color: $white;
}

.post-body {
    padding: 1.5rem;
    background-color: #FBFBFB;
    border-radius: 0px 0px .5rem .5rem;
    height: calc(100% - 220px);
}

.post-cat {
    text-decoration: none;
    display: inline-block;
    margin-bottom: .5rem;
}

.post-title {
    color: $black-text;
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 500;
    text-decoration: none;
    display: block;
    margin-bottom: .75rem;
}

.post-excerpt {
    color: $grey-text;
    line-height: 1.5;
    @include line-clamp(3, 1.5);
}

.post-item {
    height: 100%;
}

.post-img-wrapper {
    display: block;
    width: 100%;
    height: 220px;
    overflow: hidden;
    border-radius: .5rem .5rem 0px 0px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.post-meta {
    color: $primary;
    font-size: .875rem;
    > * {
        position: relative;
        &:not(:last-child) {
            margin-right: .75rem;
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 3px;
                height: 3px;
                background-color: $primary;
                border-radius: 50%;
                margin: 0 6px;
            }
        }
    }
}

.post-video-wrapper {
    @include overlay();
    &:before {
        border: none;
        content: "\e039";
        @extend .material-icons;
        font-size: 3rem;
        vertical-align: -0.255em;
        transition: all .3s;
        position: absolute;
        z-index: 4;
        color: $white;
        @include absolute-center;
    }
}
.post-single {
    strong {
        font-weight: 500;
    }
    h2 {
        font-size: 1.5rem;
    }
}
.nav-blog {
    flex-wrap: nowrap;
    overflow-x: auto;
    @include media-breakpoint-up(sm) {
        border-radius: 0.5rem 0.5rem 0 0;
    }
    @include media-breakpoint-down(sm) {
        margin: 0 -1.5rem;
        .nav-item {
            min-width: 150px;
        }
    }
}
.swiper-recent-posts {
    .swiper-slide {
        height: auto;
    }
}
.gallery-img {
    img {
        border-radius: .5rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}