.noUi-connect {
    background: $primary !important;
    height: 2px !important;
}

.noUi-handle {
    cursor: pointer !important;
    border-radius: 50% !important;
    width: 12px !important;
    height: 12px !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $primary !important;
    border: 2px solid $primary !important;
    top: -5px !important;
    right: -6px !important;

    &.noUi-active {
        box-shadow: 0px 0px 0px 16px rgba(204, 37, 57, 0.1) !important;
    }
}

.noUi-target {
    background-color: transparent !important;
    border: none !important;
}

.noUi-horizontal {
    height: 2px !important;
    box-shadow: none !important;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.noUi-handle:before,
.noUi-handle:after {
    display: none !important;
}

.noUi-connects {
    background: $input-border-color;
}

.range-wrapper {
    .form-control {
        max-width: 90px;
        font-size: .75rem;
    }

    .form-floating {
        max-width: 90px;
    }

}

.slider-value-max,
.slider-value-min {
    font-size: .875rem;
    color: $grey-text;

}

.filter-active {
    color: $grey-graphite;
    margin-right: .5rem;
    margin-bottom: .5rem;

    span {
        color: $grey-text;
    }
}

.label-float {
    &.selected {
        top: -10px;
        font-size: 12px;
        transform: translateY(0);
        color: $black-text;
        padding: 2px 4px;
    }

    color: $grey-text;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    transition: all 0.2s ease 0s;
    background-color: $white;
}

.filter-group {
    position: relative;
    margin-bottom: 1rem;

    .form-check-label {
        font-size: 1rem;
    }

    .form-check {
        margin-bottom: .75rem;
    }
}

.filter-count {
    color: $grey-text;
}

.select2-selection {
    // background-image: $form-select-indicator;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 12px;
}

.select2-container--bootstrap-5 .select2-selection {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.select2-container--bootstrap-5.select2-container--focus .select2-selection,
.select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: $input-focus-border-color !important;
    box-shadow: none !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6' style='&%2310; transform: rotate(180deg);&%2310; transform-origin: 50%25 50%25;&%2310;'/%3E%3C/svg%3E");
}

.select2-container--bootstrap-5 .select2-dropdown {
    border-color: transparent !important;
}

#clear-filters {
    display: none;
}

// .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
//     display: none !important;
// }
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected,
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected="true"]:not(.select2-results__option--highlighted) {
    background-color: transparent !important;
    color: $body-color !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.6875rem 1rem !important;
}

.select2-results__option {
    padding-right: 20px;
    vertical-align: middle;
}

.select2-results__option:before {
    content: "";
    display: inline-block;
    position: relative;
    height: 1rem;
    width: 1rem;
    border: 1px solid $grey-stroke;
    border-radius: 4px;
    background-color: #fff;
    margin-right: .875rem;
    vertical-align: middle;
}

.select2-results__option.select2-results__option--selected:before {
    content: "\e5ca";
    @extend .material-icons;
    color: $primary;
    border: 1px solid $primary;
    font-size: 1rem;
}

.select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 1px solid $black-text !important;
    border-radius: $border-radius !important;
}

.select2-results__option.select2-results__option--group:before {
    display: none;
}

.select2-results__option.select2-results__option--group .select2-results__group {
    color: $black-text !important;
    display: block;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option--group {
    padding: 0 !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option--group:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
}

.select2 {
    width: 100% !important;
}

.search_filters_wrapper {
    @include media-breakpoint-up(lg) {
        background: $white;
        border-radius: .5rem;
        padding: 1rem;
    }
}

.offcanvas-lg {
    @include media-breakpoint-up(lg) {
        .offcanvas-footer {
            display: none;
        }
    }
}

.btn-filter-collapse {
    border: 1px solid $input-border-color;
    width: 100%;
    text-align: left;
    color: $grey-text;
    font-weight: 400;
    background-color: $form-select-bg;
    display: flex;
    align-items: center;
    padding: .6875rem 1rem;

    // padding-right: .75rem;
    &:after {
        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-size: $form-select-bg-size;
        width: 1rem;
        height: .75rem;
        content: "";
        margin-left: auto;
        flex-shrink: 0;
        @include transition($accordion-icon-transition);
    }

    &[aria-expanded="true"] {
        border-color: $black-text;

        &:after {
            transform: $accordion-icon-transform;
        }
    }

    +.collapse {
        .form-check-label {
            width: 100%;

            @include hover-focus() {
                cursor: pointer;
            }
        }

        .form-check {
            padding: .5rem .75rem .5rem 2rem;
            margin-bottom: 0;

            @include hover-focus() {
                background-color: #F7F7F7;
            }
        }

        .form-floating {
            margin-left: .75rem;
            margin-right: .75rem;
            margin-top: .75rem;
        }
    }
}