.footer-wrapper {
    background-color: #FAFAFA;
}
.footer-main {
    padding-top: 6rem;
    padding-bottom: 1.5rem;
    font-size: .875rem;
    color: $black-text;
    .nav-link {
        padding: .5rem 0;
        color: $black-text;
        @include hover-focus() {
            text-decoration: underline;
        }
    }
    p {
        margin-bottom: .5rem;
    }
    .col-md-6 {
        margin-bottom: 3rem;
    }
}

.footer-copyright {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid #EAEAEA;
    color: $grey-text;
    font-size: .875rem;

    .nav-signature {
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
        .nav-link {
            padding: 0;
            @include hover-focus() {
                color: $grey-text;
                text-decoration: underline;
            }
        }
    }
    a {
        color: $grey-text;
        text-decoration: none;
        @include hover-focus() {
            text-decoration: underline;
        }
    }
}
.footer-link {
    display: flex;
    align-items: center;
    padding: .5rem 0;
    a {
        text-decoration: none;
        color: $body-color;
        @include hover-focus() {
            // color: $primary;
            text-decoration: underline;
        }
    }
    [class*="material-"] {
        font-size: 1rem;
    }
}
.footer-title {
    font-weight: 500;
    color: $black-text;
    margin-bottom: 1.125rem;
    font-size: 1rem;
}
.social-block {
    svg {

        @include hover-focus() {
            path {
                fill: $primary;
            }
        }
    }
}