/* Rules for sizing the icon. */
.md-16 {
    font-size: 1rem;
}

.md-18 {
    font-size: 18px;
}

.md-24 {
    font-size: 24px;
}

.md-36 {
    font-size: 36px;
}

.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.md-light {
    color: rgba(255, 255, 255, 1);
}

.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

img {
    @extend .img-fluid;
}

.fw-500 {
    font-weight: 500;
}

.btn-primary {
    @include hover-focus() {
        background-color: $white;
        color: $primary;
    }
}

.btn-white {
    background-color: $white;
    color: $black-text;

    @include hover-focus() {
        border-color: transparent;
        background-color: $white;
        opacity: .9;
        color: $black-text;
    }
}

.btn-edit {
    color: $grey-text;

    @include hover-focus() {
        border-color: transparent;
    }
}

.btn-icon {
    display: inline-flex;
    align-items: center;
}

.btn-more {
    color: $primary;

    @include hover-focus() {
        color: $body-color;
        border-color: transparent;
    }
}

.btn-md-100 {
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.dropdown-toggle {
    &:after {
        border: none;
        content: "\e5cf";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
    }

    &[aria-expanded="true"] {
        &:after {
            content: "\e5ce";
        }
    }
}

.section-hero {
    @include overlay();

    img {
        min-height: 224px;
        max-height: 50vh;
        width: 100%;
        object-fit: cover;
    }

    margin-bottom: 8rem;
    @include media-breakpoint-down(lg) {
        margin-bottom: 18rem;
    }
}

.hero-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3rem;
    z-index: 3;
    padding: 0 1.5rem;

    @include media-breakpoint-down(xl) {
        bottom: -6rem;
    }

    @include media-breakpoint-down(lg) {
        bottom: initial;
        top: calc(100% - 9rem);
    }

    h1 {
        color: $white;
        margin-bottom: 1rem;
        line-height: 1.5;
    }
}

.block-finder {
    background: $white;
    box-shadow: 0 0 15px 2px rgba(209, 209, 209, 0.2);
    border-radius: .5rem;
    padding: 1.5rem 2rem;

    @include media-breakpoint-down(lg) {
        padding: 1.5rem;
    }
}

.section-spacer {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.section-spacer-lg {
    @include media-breakpoint-up(lg) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

.section-title {
    margin-bottom: 2.5rem;
    color: $black;
    font-size: 2.5rem;
    line-height: 1.25;

    @include media-breakpoint-down(lg) {
        font-size: 2rem;
    }
}

.section-top {
    @include overlay();

    img {
        height: 224px;
        object-fit: cover;
        width: 100%;
    }

    h1 {
        color: $white;
        margin-bottom: 1rem;
    }

    .top-content {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 3;
        bottom: 0;
        padding: 3.5rem 1.5rem;
    }
}

.nav-pills {
    background-color: $white;

    @include media-breakpoint-up(lg) {
        border-radius: .5rem .5rem 0 0;
    }

    .nav-link {
        border-bottom: 2px solid transparent;
        border-radius: .5rem .5rem 0 0;

        &.active {
            border-bottom: 2px solid $primary;
            font-weight: 500;
        }
    }
}

.block-box {
    background: $white;
    border-radius: .5rem;
    padding: 1.5rem;
}

.block-box-lg {
    @include media-breakpoint-up(lg) {
        padding: 3.5rem;
    }
}

.accordion-item {
    margin-bottom: .5rem;
    border-radius: .5rem;
}

.accordion-button {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;
}

.accordion-collapse {
    &.show {
        border-top: 1px solid $body-bg;
    }
}

.contact-left {
    background-color: $white;
    padding: 3.5rem;
    border-radius: .5rem 0 0 .5rem;

    @include media-breakpoint-down(md) {
        padding: 2rem 1.5rem;
        border-radius: .5rem .5rem 0 0;
    }
}

.contact-right {
    height: 100%;
    border-radius: 0 .5rem .5rem 0;

    .map {
        border-radius: 0 .5rem .5rem 0;

        @include media-breakpoint-down(md) {
            border-radius: 0 0 .5rem .5rem;
        }
    }
}

.map {
    width: 100%;
    height: 100%;
    min-height: 265px;
}

// Fixes potential theme css conflict.
.map img {
    max-width: inherit !important;
}

.text-grey {
    color: $grey-text;
}

.border-md-end {
    @include media-breakpoint-up(md) {
        border-right: 1px solid $grey-stroke;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-floating {
    >label {
        background-color: $white;
        color: #666666;
        padding: 2px 4px;
        height: auto;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: initial;
    }

    .form-control:focus~label {
        color: #212121;
    }


    // >textarea {
    //     &.form-control {
    //         height: 6rem;
    //     }
    // }
}

.form-check-label {
    font-size: .875rem;

    a {
        color: $black-text;

        @include hover-focus() {
            color: $primary;
        }
    }
}

.swiper {
    &.swiper-horizontal {
        padding-bottom: 3rem;
    }
}

.block-partners {
    height: 100%;
    // padding: 0;
    // margin: 0;
    position: relative;
}

.partners-height {
    height: calc((70px*2) + 96px);
}

.swiper-partners {
    width: 100%;
    height: 100%;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc((100% - 48px) / 2) !important;
        // height: 70px;
    }

    .swiper-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
    }
}

.testimonial {
    padding: 1.5rem;
    padding-bottom: 3rem;
    border-radius: $border-radius;
    border: 1px solid $primary;
    position: relative;
    height: 100%;

    &:after {
        position: absolute;
        right: 2rem;
        content: "\0022";
        font-size: 7rem;
        color: #CC2539;
        top: -3rem;
        transform: skew(-25deg);
    }

    img {
        margin-bottom: 1rem;
    }
}

.testimonial-author {
    color: $black-text;
}

.testimonial-content {
    margin-bottom: 1rem;
}

.swiper-testimonials {
    &.swiper {
        padding-top: 3.5rem;
        margin-top: -3.5rem;

        @include media-breakpoint-down(sm) {
            margin-right: -1.5rem;
        }
    }

    .swiper-slide {
        height: auto;
    }

    @include media-breakpoint-up(lg) {
        .swiper-pagination {
            display: none;
        }
    }
}

.team-item {
    color: $grey-text;
    height: 100%;
    border: none;

    .card-title {
        color: $black-text;
        font-size: 1.125rem;
    }

    .row {
        height: 100%;

        @include media-breakpoint-down(lg) {
            .col-lg-4 {
                height: 200px;
            }

            .col-lg-8 {
                height: 100%;
            }
        }
    }

    img {
        border-top-left-radius: .5rem;
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            border-bottom-left-radius: .5rem;
        }

        @include media-breakpoint-down(lg) {
            border-top-right-radius: .5rem;
        }
    }
}

.team-link {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;

    a {
        text-decoration: none;
        color: $grey-text;

        @include hover-focus() {
            text-decoration: underline;
        }
    }

    [class*="material-"] {
        font-size: 1rem;
    }
}

.to-edge {
    @include media-breakpoint-down(sm) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        border-radius: 0;
    }
}

.step-content {
    display: none;
}

.step {
    // display: none;
    background-color: #fff;
    border-radius: .5rem;
    margin-bottom: .5rem;
}

.step-title {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
}

.step-content {
    padding: 2rem 1.5rem;
    border-top: 1px solid $body-bg;
}

.service-item {
    color: $black-text;
    // margin-bottom: 4.5rem;
    max-width: 364px;
    // font-size: 1.125rem;
    font-weight: 500;

    svg {
        margin-bottom: 1.5rem;
        width: 64px;
        height: 64px;
    }
}

.list-checked {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "\e5ca";
            @extend .material-icons;
            // font-size: 1rem;
            vertical-align: -0.255em;
            transition: all .3s;
            color: $primary;
            position: absolute;
            top: 0;
            left: -2rem;
        }
    }
}

.block-cta {
    position: relative;
    border-radius: .5rem;
    height: 100%;
    min-height: 389px;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: .5rem;
    }
}

.cta-content {
    color: $white;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1.5rem 1rem;
}

.pagination {
    .page-link {
        font-size: 1rem;
        // border-radius: 50%;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        border: none;
    }

    .material-icons {
        font-size: 1rem;
        line-height: 2rem;
    }

    .page-item {
        +.page-item {
            margin-left: .5rem;
        }

        // &:first-child {
        //     .page-link {
        //         border-radius: 50%;
        //     }
        // }

        // &:last-child {
        //     .page-link {
        //         border-radius: 50%;
        //     }
        // }
    }
}

.input-group {
    .btn {
        padding: 0 1rem;
    }
}

.search-input {
    border-color: $white;
}

.btn-search {
    background-color: $white;

    i {
        color: $primary;
    }
}

.btn-filter-mobile {
    background-color: $white;
    color: $grey-text;
    font-weight: 400;
    justify-content: center;

    @include hover-focus() {
        border-color: transparent;
        background-color: $white;
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.products-sort-order {
    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    .btn {
        color: $grey-text;
        font-weight: 400;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}

.breadcrumb {
    margin-top: 1rem;
    @include media-breakpoint-down(md) {
        font-size: .875rem;
    }
}

.breadcrumb-item {
    a {
        color: $grey-text;
        text-decoration: none;
    }
}

.icon-56 { 
    width: 56px; 
    height: 56px;
    display: block;
}

.icon { 
  width: 40px; 
  height: 40px;
}
.report-item {
    font-size: 1.125rem;
    color: $black-text;
    font-weight: 500;
    height: 100%;
}

textarea {
    &.form-control {
        padding: 0.6875rem 1rem;
    }
}

.form-sell-wrapper {
    .btn-edit {
        display: none;
    }
    .step-prev {
        .btn-edit {
            display: inline-flex;
        }
        .icon-complete {
            display: inline-block;
        }
    }
}


.btn-custom-file {
    color: $primary;
    background-color: $secondary;
    border-color: $primary;
    font-weight: 400;


    &.btn-icon {
        display: inline-flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            width: initial;
        }
    }
}

#submit-btn,
#next-btn,
.btn-submit,
.btn-next {
    min-width: 200px;
}

.icon-complete {
    width: 1.5rem;
    display: none;
}


.cc-window.cc-banner.cc-type-info.cc-theme-block.cc-bottom {
    max-width: 730px;
    margin: 0 auto;
    padding: 1.5rem;
    box-shadow: 0px 0px 16px rgba(193, 193, 193, 0.12);
    border-radius: .5rem;
    font-family: $font-family-base;
}

.cc-btn.cc-dismiss {
    border-radius: .25rem;
    padding: .75rem 1.5rem;
    text-decoration: none;
    line-height: 1;
    border: 1px solid $primary!important;
    font-weight: 500;
}

.cookies-typography {
    h4 {
        color: $black-text;
    }
    a {
        color: $body-color;
    }

}
.accordion-body {
    color: $grey-text;
}
.form-floating > textarea {
    height: 9rem !important;
}
.form-floating > textarea + label {
    top: 24px !important;
}

.filter-group-main {
    .form-select {
        padding: 0.6875rem 3rem 0.6875rem 0.75rem;
    }
}
.content-form {
    z-index: 1;
    position: relative;
}
.xdsoft_datetimepicker.xdsoft_inline {
    position: relative !important;
    z-index: -1 !important;
}