.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #ddd;
	width: 278px;
	max-width: none;
	padding: 0;
	margin-top: 7px;
	top: 100px;
	left: 20px;
	z-index: 3001;
	display: none;
	font-family: arial;
	font-size: 15px;
	line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
	position: absolute;
	display: inline-block;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: "";
}

.daterangepicker:before {
	top: -7px;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
	top: -6px;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
	right: 9px;
}

.daterangepicker.opensleft:after {
	right: 10px;
}

.daterangepicker.openscenter:before {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
}

.daterangepicker.openscenter:after {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
}

.daterangepicker.opensright:before {
	left: 9px;
}

.daterangepicker.opensright:after {
	left: 10px;
}

.daterangepicker.drop-up {
	margin-top: -7px;
}

.daterangepicker.drop-up:before {
	top: initial;
	bottom: -7px;
	border-bottom: initial;
	border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
	top: initial;
	bottom: -6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
	float: none;
}

.daterangepicker.single .drp-selected {
	display: none;
}

.daterangepicker.show-calendar .drp-calendar {
	display: block;
}

.daterangepicker.show-calendar .drp-buttons {
	display: block;
}

.daterangepicker.auto-apply .drp-buttons {
	display: none;
}

.daterangepicker .drp-calendar {
	display: none;
	max-width: 270px;
}

.daterangepicker .drp-calendar.left {
	padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
	padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
	border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
	color: #fff;
	border: solid black;
	border-width: 0 2px 2px 0;
	border-radius: 0;
	display: inline-block;
	padding: 3px;
}

.daterangepicker .calendar-table .next span {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
	border-radius: 4px;
	border: 1px solid transparent;
	white-space: nowrap;
	cursor: pointer;
}

.daterangepicker .calendar-table {
	border: 1px solid #fff;
	border-radius: 4px;
	background-color: #fff;
}

.daterangepicker .calendar-table table {
	width: 100%;
	margin: 0;
	border-spacing: 0;
	border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background-color: #eee;
	border-color: transparent;
	color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	background-color: #fff;
	border-color: transparent;
	color: #999;
}

.daterangepicker td.in-range {
	background-color: #ebf4f8;
	border-color: transparent;
	color: #000;
	border-radius: 0;
}

.daterangepicker td.start-date {
	border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
	border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
	border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
	background-color: #357ebd;
	border-color: transparent;
	color: #fff;
}

.daterangepicker th.month {
	width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 56%;
}

.daterangepicker select.yearselect {
	width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	width: 50px;
	margin: 0 auto;
	background: #eee;
	border: 1px solid #eee;
	padding: 2px;
	outline: 0;
	font-size: 12px;
}

.daterangepicker .calendar-time {
	text-align: center;
	margin: 4px auto 0 auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
}

.daterangepicker .drp-buttons {
	clear: both;
	text-align: right;
	padding: 8px;
	border-top: 1px solid #ddd;
	display: none;
	line-height: 12px;
	vertical-align: middle;
}

.daterangepicker .drp-selected {
	display: inline-block;
	font-size: 12px;
	padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
	margin-left: 8px;
	font-size: 12px;
	font-weight: bold;
	padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
	border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
	border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
	float: none;
	text-align: left;
	margin: 0;
}

.daterangepicker.show-calendar .ranges {
	margin-top: 8px;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 12px;
	padding: 8px 12px;
	cursor: pointer;
}

.daterangepicker .ranges li:hover {
	background-color: #eee;
}

.daterangepicker .ranges li.active {
	background-color: #08c;
	color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
	.daterangepicker {
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 140px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker.single .drp-calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .drp-calendar {
		float: left;
	}

	.daterangepicker {
		direction: ltr;
		text-align: left;
	}

	.daterangepicker .drp-calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .drp-calendar.right {
		margin-left: 0;
	}

	.daterangepicker .drp-calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		padding-right: 8px;
	}

	.daterangepicker .ranges,
	.daterangepicker .drp-calendar {
		float: left;
	}
}

@media (min-width: 730px) {
	.daterangepicker .ranges {
		width: auto;
	}

	.daterangepicker .ranges {
		float: left;
	}

	.daterangepicker.rtl .ranges {
		float: right;
	}

	.daterangepicker .drp-calendar.left {
		clear: none !important;
	}
}
.bg-va {
	width: 36px;
	height: 36px;
	background: url("./../img/css_sprites.png") -10px -10px;
}

.bg-garantija {
	width: 36px;
	height: 36px;
	background: url("./../img//css_sprites.png") -66px -10px;
}

.bg-servise {
	width: 36px;
	height: 36px;
	background: url("./../img//css_sprites.png") -10px -66px;
}

.bg-tebenaudojamas {
	width: 36px;
	height: 36px;
	background: url("./../img//css_sprites.png") -66px -66px;
}

.bg-istorija {
	width: 36px;
	height: 36px;
	background: url("./../img//css_sprites.png") -122px -10px;
}

.product-features {
	> * {
		display: inline-block;
		margin-right: 0.75rem;
	}
}

.product-thumbnail {
	border-radius: 0.5rem 0.5rem 0 0;
	display: block;

	img {
		width: 100%;
		height: 172px;
		object-fit: cover;
		border-radius: 0.5rem 0.5rem 0 0;

		@include media-breakpoint-up(xxxl) {
			height: 235px;
		}
	}
}

.product-title {
	font-size: 1rem;
	margin-bottom: 1rem;

	a {
		color: $grey-graphite;
		text-decoration: none;
	}
}

.product-miniature {
	position: relative;
	border-radius: 0.5rem;
	height: 100%;

	// .product-title {
	//     height: 2em;
	// }
	.product-description {
		background-color: $white;
		padding: 1rem;
		border-radius: 0 0 0.5rem 0.5rem;
		height: calc(100% - 172px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-breakpoint-up(xxxl) {
			height: calc(100% - 235px);
		}
	}
}

.question-mark {
	color: $grey-text;

	span {
		font-size: 1rem;
	}

	@include hover-focus() {
		cursor: pointer;
	}
}
.question-mark-product {
	display: inline-block;
	position: relative;
	top: -10px;
}

.thumbnail-container {
	height: 100%;
}

.price {
	font-size: 1.125rem;
	font-weight: 700;
	color: $black-text;

	&.price-new {
		color: $primary;
	}
}

.price-regular {
	text-decoration: line-through;
	font-size: 0.75rem;
}

.price-lease {
	font-size: 1.125rem;
}

.product-flags {
	padding-left: 0;
	list-style: none;
	position: absolute;
	top: 0.75rem;
	left: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	// width: 100%;
	pointer-events: none;
	font-style: normal;
	font-weight: 500;
	font-size: 0.875rem;

	.product-flag {
		width: fit-content;
		padding: 0.25rem 0.5rem;
		margin-top: 0.25rem;
		color: $primary;
		pointer-events: auto;
		background: $white;
		display: inline-flex;
		align-items: center;
		border-radius: 0px 4px 4px 0px;
		position: relative;
		// height: 2rem;

		// &.discount-percentage,
		// &.discount-amount,
		// &.discount {
		//     background-color: $danger;
		// }

		// &.new {
		//     background-color: $success;
		// }
	}
}

.product-badges {
	> * {
		background-color: $gray-100;
		color: $grey-text;
		font-weight: 500;
		font-size: 0.75rem;
		line-height: 1.4;
		padding: 0.375rem 0.5rem;

		[class*="material-"] {
			color: $primary;
			font-size: 0.75rem;
		}
	}
}

.data-title {
	color: $grey-text;
}

.data-value {
	color: $black-text;
	font-size: 1.125rem;
	font-weight: 500;
}

.product-data {
	margin-bottom: 1.5rem;

	.icon {
		margin-bottom: 0.5rem;
	}

	@include media-breakpoint-down(md) {
		.data-title {
			font-size: 0.875rem;
		}

		.data-value {
			font-size: 1rem;
		}
	}
}

.specification-item {
	color: $grey-graphite;
	display: flex;
	justify-content: space-between;
	background-color: $white;
	border-radius: 0.5rem;
	font-size: 1.125rem;
	padding: 0.75rem 1rem;
	margin-bottom: 0.5rem;

	.text-black {
		font-weight: 500;
	}
}

.swiper-products {
	.swiper-slide {
		height: auto;

		.product {
			height: 100%;
		}
	}
}

.swiper-nav-outside {
	position: relative;

	.swiper-button-prev {
		left: -1.5rem;
	}

	.swiper-button-next {
		right: -1.5rem;
	}
}

.product-gallery {
	> :nth-child(-n + 3) {
		@extend .col-lg-4;

		@include media-breakpoint-up(lg) {
			height: auto;
		}
	}

	> :nth-child(-n + 2) {
		@extend .col-md-6;

		@include media-breakpoint-up(md) {
			height: auto;
		}
	}

	> :first-child() {
		@extend .col-12;
		height: auto;
	}

	> * {
		width: calc(100% / 9);
		height: 100px;
		display: block;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		@include media-breakpoint-down(lg) {
			width: calc(100% / 6);
		}

		@include media-breakpoint-down(md) {
			width: calc(100% / 3);
		}
	}
}

.gallery-showmore {
	display: none;
}

.gallery-exterior,
.gallery-interior,
.gallery-showmore {
	a {
		display: block;
		position: relative;
		color: $white;
		text-decoration: none;
		font-weight: 500;
		height: 100px;
	}

	.showmore-overlay {
		background: rgba(0, 0, 0, 0.6);
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.fancybox__thumb {
	opacity: 0.4;
}

.fancybox__thumb:hover,
.is-nav-selected .fancybox__thumb {
	opacity: 1;
}

.is-nav-selected .fancybox__thumb::after {
	display: none;
}

.description-long {
	h2 {
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.125rem;
	}

	h4 {
		font-size: 1rem;
	}
}

.gallery-upload-item {
	position: relative;

	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
		border-radius: 0.5rem;
	}

	.remove {
		position: absolute;
		top: 0.5rem;
		right: 1rem;
		color: white;
		cursor: pointer;
	}
}

.page-product-single {
	.price {
		font-size: 2rem;
	}

	.price-lease {
		font-size: 1.5rem;
	}

	.price-regular {
		font-size: 1.125rem;
	}
}
.content-success {
	display: none;
}
.row-multi-select {
	display: flex;
	margin-left: -6px;
	margin-right: -6px;
	.form-floating {
		width: 50%;
		padding: 0 6px;
	}
}
.air-datepicker.-inline- {
	width: 100%;
	margin-bottom: 0.75rem;
}
.air-datepicker-cell.-current- {
	color: #cc2539 !important;
}
.air-datepicker-cell.-selected- {
	background: #cc2539 !important;
}
.loan-card {
  border-radius: .5rem; 
  border: 1px solid #DDD;
  padding: 1rem 1.25rem;
  a {
    border-radius: 4px;
    border: 1px solid $danger;
    background: $white;
    color: $danger;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }
	p {
		color: #121212;
	}
	.loan-sum {
		font-size: 1.125rem;
		font-weight: 500;
	}
	.loan-description {
		color: #626262;
	}
}
.form-floating > :disabled ~ label::after, .form-floating > .form-control:disabled ~ label::after {
  display: none !important;
}

.text-small {
  margin-top: 1.5rem;
  color: #626262;
  font-size: .875rem;
}