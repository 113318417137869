.top-bar {
    background-color: #F4F1F1;

    .nav-link {
        color: $black-text;
        font-size: .75rem;
    }

    [class*="material-"] {
        font-size: 1.125rem;
        color: $primary;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}
.nav-contact {
    margin-left: -1rem;
}

.right-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.main-menu {
    .nav-link {
        color: $black-text;
        font-weight: 500;

        @include hover-focus() {
            color: $primary;
        }
    }
}

.dropdown-menu-lang {
    border-color: transparent;
    min-width: initial;
}

// #offcanvasNavbar {
//     &.show {
//         .collapse {
//             // @extend .show;
//             display: block;
//         }
//     }
// }
.js-top-menu-bottom {
    .nav-link {
        color: $black-text;
        font-weight: 500;
        padding: 1rem 0;
    }

}