$material-icons-font-path: './../fonts/material/' !default;

$grid-gutter-width: 3rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1312px,
  xxxl: 1792px
);


$primary: #CC2539;
$secondary: #FAE9EB;
$success: #0FB21F;

$grey: #787878;
$grey-stroke: #D9D9D9;
$grey-text: #626262;
$grey-light: #ACACAC;
$grey-lighter: #F7F5F5;
$grey-graphite: #23292E;

$black-graphite: #141719;
$black-text: #121212;
$light: #F7F5F5;

$body-bg: $light;

$font-family-base: 'DM Sans', sans-serif;
$h1-font-size: 40px;
$h2-font-size: 32px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 16px;

$border-radius: .25rem;

$btn-font-weight: 500;
$btn-padding-y: .6875rem;
$btn-padding-x: 1.5rem;
$btn-focus-box-shadow: none;

$navbar-nav-link-padding-x: 1rem;
$navbar-padding-y: 1rem;

$navbar-toggler-focus-width: 0;
$navbar-light-toggler-border-color: transparent;

$input-border-color: #E0E0E0;
$input-padding-y: .6875rem;
$input-padding-x: 1.5rem;
$input-bg: #fff;
$input-focus-box-shadow: none;
$input-focus-border-color: #212121; 
$input-focus-color: #212121; 
$form-select-focus-box-shadow: none;

$form-floating-input-padding-t: .782rem;
$form-floating-input-padding-b: .782rem;
$form-floating-padding-x: 1rem;
$form-floating-padding-y: .6875rem;
$form-floating-height: 3rem;
$form-floating-label-opacity: 1;
// $form-floating-label-transform: scale(.85) translateY(-1.45rem) translateX(.15rem);
$form-floating-label-transform: scale(0.85) translateY(-2.65rem);

$nav-link-padding-y: 1rem;
$nav-pills-link-active-bg: #fff;
$nav-pills-link-active-color: $primary;
$nav-link-color: $grey-text;
$nav-pills-border-radius: 0;

$accordion-border-color: transparent;
$accordion-button-active-bg: transparent;
$accordion-bg: #fff;
$accordion-padding-x: 1.25rem;
// $accordion-button-padding-x: 1.25rem;
$accordion-button-padding-y: 1rem;
$accordion-button-focus-box-shadow: none;
$accordion-button-focus-border-color: $primary;
$accordion-button-active-color: $black-text;
// $accordion-button-active-color: $grey-darker;
// $accordion-button-color: $grey-darker;

$card-border-color: transparent;
$card-border-radius: .5rem;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$tooltip-bg: $secondary;
$tooltip-color: $grey-graphite;

$pagination-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-color: $grey-text;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-hover-color:            #fff;
$pagination-hover-bg:               $primary;
$pagination-hover-border-color:     $primary;
$pagination-focus-color:            #fff;
$pagination-focus-bg:               $primary;
$pagination-focus-outline:          0;


$breadcrumb-active-color: $grey-graphite;

$modal-header-border-color: transparent;

$form-check-input-border:                 1px solid $grey-stroke;
$form-check-input-checked-color:          $primary;
$form-check-input-checked-bg-color:       #fff;
$form-check-input-checked-border-color:   $primary;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/></svg>");


$offcanvas-padding-y: 1.5rem;
$offcanvas-padding-x: 1.5rem;