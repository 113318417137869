@import "fonts";
@import "variables";
@import "mixins";
@import "material/material-icons";
@import "node_modules/bootstrap/scss/bootstrap";
@import "global";
@import "footer";
@import "header";
@import "blog";
@import "product";
@import "swiper/swiper-bundle";
@import "fancybox/fancybox";
@import "nouislider/nouislider";
@import "filters";
@import "select2/select2";
// @import "date-picker";
@import "datetimepicker";
@import "pannellum";
// @import "jquery-ui.structure";
// @import "select2/bootstrap5";
// If you need to import Bootstrap as well:
@import "node_modules/select2-bootstrap-5-theme/src/select2-bootstrap-5-theme";
// Or if you have already imported Bootstrap:
@import "node_modules/select2-bootstrap-5-theme/src/include-all";


:root {
    --swiper-navigation-size: 24px;
    --swiper-theme-color: #CC2539;
}

html, body {
    height: 100%;
}