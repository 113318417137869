@mixin hover-focus($self: false) {
    @if $self {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}


@mixin overlay() {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .4);
        transition: all 1s;
        -webkit-transition: all 1s;
    }

    // >* {
    //     z-index: 100;
    //     position: relative;
    // }
}

@mixin line-clamp($numLines: 1, $lineHeight: 1.412) {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: block;
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    max-height: $numLines * $lineHeight + unquote('em');
}

@mixin absolute-center {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}